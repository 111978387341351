const moveables = Array.from(document.querySelectorAll('.container-move'))
if (moveables.length) {
  const DELTA = -30
  let cachedRects = []
  let cachedChildren = []
  let isScheduled = false
  let mousePosition
  let currentTarget

  function getRect(index) {
    if (!cachedRects[index]) {
      cachedRects[index] = moveables[index].getBoundingClientRect()
    }
    return cachedRects[index]
  }

  function getChild(index) {
    if (!cachedChildren[index]) {
      cachedChildren[index] = moveables[index].querySelector('.img-move')
    }
    return cachedChildren[index]
  }

  function schedule() {
    if (isScheduled) return
    window.requestAnimationFrame(() => {
      position()
      isScheduled = false
    })
  }

  function position() {
    const { width, height } = getRect(currentTarget)
    const x = ((mousePosition.x - width / 2) / width) * DELTA
    const y = ((mousePosition.y - height / 2) / height) * DELTA
    getChild(currentTarget).style.transform = `translate(${x}px, ${y}px)`
  }

  moveables.forEach((moveable, index) => {
    moveable.addEventListener('mousemove', event => {
      const { clientX, clientY } = event
      const { left, top } = getRect(index)
      currentTarget = index
      mousePosition = {
        x: clientX - left,
        y: clientY - top,
      }
      schedule()
    })
  })

  window.addEventListener('resize', () => {
    cachedRects = []
  })
}
