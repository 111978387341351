import $ from 'jquery'

var footerSelect = document.getElementsByClassName('select-box')

for (var i = 0; i < footerSelect.length; i++) {
  footerSelect[i].onclick = function (event) {
    this.classList.toggle('select-box--active')
  }
}

var faqSelect = document.getElementsByClassName('faq-box')

for (var i = 0; i < faqSelect.length; i++) {
  faqSelect[i].onclick = function (event) {
    this.classList.toggle('faq-box--active')
  }
}

$('#menu-box').click(function () {
  $('body').toggleClass('overflow-hidden w-full h-full')
  $('#header').toggleClass('header--open')
})

const tabs = Array.from(document.querySelectorAll('[data-tab-id]'))
const tabButtons = Array.from(document.querySelectorAll('[data-for-tab-id]'))

function toggleTabButtons(active) {
  tabButtons.forEach(tabButton => {
    if (tabButton === active) {
      tabButton.classList.add('bg-primary', 'text-white')
      tabButton.classList.remove('text-black', 'hover:text-secondary')
    } else {
      tabButton.classList.remove('bg-primary', 'text-white')
      tabButton.classList.add('text-black', 'hover:text-secondary')
    }
  })
}

function toggleTabs(active) {
  tabs.forEach(tab => {
    tab.classList.toggle('hidden', tab !== active)
  })
}

tabButtons.forEach(tabButton => {
  tabButton.addEventListener('click', event => {
    const id = event.target.getAttribute('data-for-tab-id')
    toggleTabButtons(event.target)
    toggleTabs(document.querySelector(`[data-tab-id=${id}]`))
  })
})